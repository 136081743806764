import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.text.link,
    border: 'none',
    background: 'none',
    padding: '5px 0',
  },
  listContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
    border: `1px solid ${theme.palette.CHANGEME[9]}`,
    borderRadius: 5,
    marginTop: 12,
  },
  listItem: {
    color: theme.palette.black,
    padding: '5px 0',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.gray1,
    },
  },
  listText: {
    marginLeft: 8,
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  textArea: {
    marginTop: 12,
  },
}));
