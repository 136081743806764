export default theme => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    padding: '0 15px',
    height: 40,
    alignItems: 'center',
    backgroundColor: theme.palette.filter.background.desktop,
    borderRadius: 4,
  },

  large: {
    height: 41,
  },

  searchIcon: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('postmd')]: {
      color: theme.palette.text.primary,
    },
  },

  iconButton: {
    padding: 12,
    marginRight: -12,
    '&:focus-visible': {
      backgroundColor: theme.fade(theme.palette.green0, 0.04),
    },
  },

  clearIcon: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('postmd')]: {
      color: theme.palette.text.primary,
    },
  },
});
