import React, { useState, useContext, useEffect } from 'react';

import { IntegrationsContext } from 'components/IntegrationsPanel';

import Modal from 'components/Modal';
import ConfigurationTableModal from '../ConfigurationTableModal';
import ShowConfiguration from '../ShowConfiguration';
import CreateResource from './CreateResource';
import ShowResource from './ShowResource';

export const useIntegrationsModals = () => {
  const { toolId, canEdit } = useContext(IntegrationsContext);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState(<></>);

  const closeModal = () => {
    setShow(false);
    setContents(<></>);
    setTitle('');
  };

  const showModal = (modalContents, modatTitle = '') => {
    if (modatTitle) {
      setTitle(modatTitle);
    }
    setContents(modalContents);
    setShow(true);
  };

  const setModalTitle = newTitle => {
    setTitle(newTitle);
  };

  const showResource = resourceId =>
    showModal(<ShowResource toolId={toolId} resourceId={resourceId} onClose={closeModal} setTitle={setTitle} />);
  const createResource = canEdit
    ? () => showModal(<CreateResource onClose={closeModal} formValue={{}} />, 'Add Implementation Resource')
    : () => {};
  const editResource = canEdit
    ? resource =>
        showModal(<CreateResource onClose={closeModal} formValue={resource} />, 'Edit Implementation Resource')
    : () => {};
  const showConfiguration = configId =>
    showModal(<ShowConfiguration toolId={toolId} configId={configId} onClose={closeModal} setTitle={setTitle} />);
  const createConfiguration = canEdit
    ? () => showModal(<ConfigurationTableModal onClose={closeModal} formValue={{}} />, 'Add Canvas Configuration')
    : () => {};
  const editConfiguration = canEdit
    ? resource =>
        showModal(<ConfigurationTableModal onClose={closeModal} formValue={resource} />, 'Edit Canvas Configuration')
    : () => {};

  return {
    showModal,
    closeModal,
    setModalTitle,
    showResource,
    createResource,
    editResource,
    showConfiguration,
    createConfiguration,
    editConfiguration,
    integrationsModalsState: {
      showModal: show,
      modalTitle: title,
      modalContents: contents,
      onClose: closeModal,
    },
  };
};

interface IntegrationsModalsProps {
  showModal?: boolean;
  onClose: () => void;
  modalTitle?: string | undefined;
  modalContents: React.ReactElement;
}

const IntegrationsModals = ({
  showModal = false,
  onClose,
  modalTitle = '',
  modalContents = <></>,
}: IntegrationsModalsProps) => {
  const [open, setOpen] = useState(showModal);
  const [title, setTitle] = useState(modalTitle);
  const [contents, setContents] = useState(modalContents);

  useEffect(() => {
    setTitle(modalTitle);
    setContents(modalContents);
    setOpen(showModal);
  }, [showModal, modalTitle, modalContents]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      title={title}
      button={false}
    >
      {contents}
    </Modal>
  );
};

export default IntegrationsModals;
